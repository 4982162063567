import React from 'react'

const Domicilio = () => {
  return (
    <div className="channel-container">
    <h1 className="boletinera"> MidTown Jalisco</h1>
    <p className="boletinera"> Av Adolfo López Mateos Nte 2405, <br/>Italia Providencia, 44648 <br/>Guadalajara, Jal.</p>
    </div>
  )
}

export default Domicilio