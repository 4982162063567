const CardData18 = [
  {
    title: 'Tina Turner',
    description:
      'Anna Mae Bullock, más conocida como Tina Turner, es una cantante, compositora, bailarina, actriz y coreógrafa de nacionalidad suiza y origen estadounidense, cuya carrera se desarrolló durante más de cincuenta años, además de ser una de las principales y mayores componentes de rock siendo considerada como la «Reina del Rock». Se retiró del escenario y la música en 2013 a la edad de setenta y tres años después de una carrera musical de cincuenta y cuatro años.',
    videourl:
      'https://youtube.com/playlist?list=PLGRnTVgjhDFKabSWJfU4s-pkLwTz6jVJP',
    price: '$50',
    coverImg: 'TinaTurner.jpg',
    category: 'Jazz',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.tinaturnerofficial.com/',
    youtube: 'https://www.youtube.com/channel/UCfBkblXPcSNt784AZz5J6AQ',
    facebook: 'https://www.facebook.com/TinaTurner/',
    instagram: 'https://www.instagram.com/tinaturner/?hl=en',
    twitter: 'https://twitter.com/LoveTinaTurner',
    spotify:
      'https://open.spotify.com/artist/1zuJe6b1roixEKMOtyrEak?si=vmbXj7mUSjWUXe6AujvE8g&nd=1',
    deezer: 'https://www.deezer.com/mx/artist/1454',
    apple: 'https://music.apple.com/mx/artist/tina-turner/488075',
    soundcloud: 'https://soundcloud.com/tina-turner-official',
    tidal: 'https://listen.tidal.com/artist/9214',
    wiki: 'https://es.wikipedia.org/wiki/Tina_Turner',
    ticketmaster:
      'https://www.ticketmaster.com/tina-turner-tickets/artist/736358',
    tiktok: 'https://www.tiktok.com/@tina.turner.love?lang=es',
    napster: 'https://us.napster.com/artist/tina-turner',
  },
  {
    title: 'Elvis Presley',
    description:
      'Elvis Aaron Presley, también conocido simplemente como Elvis, fue un cantante y compositor estadounidense, considerado como uno de los iconos culturales más populares del siglo XX. Es apodado como el rey del rock and roll..',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_ls-RBrPnMgwsRFj03cD-wlk_R_f-nOf4o',
    price: '$50',
    coverImg: 'ElvisPresley.jpg',
    category: 'Oldies Rock',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.elvisthemusic.com/',
    youtube: 'https://www.youtube.com/channel/UCW6G95TBLCh5SdC-DHDSf5w',
    facebook: 'https://www.facebook.com/elvis',
    instagram: 'https://www.instagram.com/elvis/',
    twitter: 'https://twitter.com/ElvisPresley',
    spotify: 'https://open.spotify.com/artist/43ZHCT0cAZBISjO8DG9PnE',
    deezer: 'https://www.deezer.com/mx/artist/1125',
    apple: 'https://music.apple.com/mx/artist/elvis-presley/197443',
    soundcloud: 'https://soundcloud.com/elvissonymusic',
    tidal: 'https://listen.tidal.com/artist/1588',
    wiki: 'https://es.wikipedia.org/wiki/Elvis_Presley',
    ticketmaster:
      'https://www.ticketmaster.com/elvis-presley-in-concert-tickets/artist/1413196',
    tiktok: 'https://www.tiktok.com/@elvispresley?lang=es',
    napster: 'https://us.napster.com/artist/elvis-presley',
  },
  {
    title: 'Lauryn Hill',
    description:
      'Lauryn Hill (East Orange, Nueva Jersey, 26 de mayo de 1975) es una rapera, cantante, actriz y productora discográfica estadounidense galardonada 8 veces con el premio Grammy. Fue miembro de The Fugees antes de empezar una carrera en solitario.',
    videourl:
      'http://youtube.com/playlist?list=OLAK5uy_m0PVFfyOJH8trqZucyaODwytdicqitwGQ',
    price: '$50',
    coverImg: 'laurynHill.jpg',
    category: 'Rythm & Blues',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://mslaurynhill.com/',
    youtube: 'https://www.youtube.com/results?search_query=lauryn+hill',
    facebookurl: 'https://www.facebook.com/mslaurynhill',
    instagram: 'https://www.instagram.com/mslaurynhill/',
    twitter: 'https://twitter.com/MsLaurynHill',
    spotify: 'https://open.spotify.com/artist/2Mu5NfyYm8n5iTomuKAEHl',
    deezer: 'https://www.deezer.com/mx/artist/1117',
    apple: 'https://music.apple.com/mx/artist/lauryn-hill/21434',
    soundcloud: 'https://soundcloud.com/lauryn-hill-official',
    tidal: 'https://listen.tidal.com/artist/8201663',
    wiki: 'https://es.wikipedia.org/wiki/Lauryn_Hill',
    ticketmaster:
      'https://www.ticketmaster.com/ms-lauryn-hill-tickets/artist/795402',
    tiktok: 'https://www.tiktok.com/@laurynhillfannn?lang=es',
    napster: 'https://us.napster.com/artist/lauryn-hill',
  },

  {
    title: 'Creedence Clearwater Revival',
    description:
      'Creedence Clearwater Revival o Creedence fue una banda estadounidense de Rock, Blues y Soul, popular a fines de la década de 1960 y comienzos de los 70. Considerada una de las mejores bandas de la historia y una de las más populares e influyentes.',
    videourl:
      'https://youtube.com/playlist?list=PLtzV2248lUVXwv8_rstiiTON7gHjR9u4s',
    price: 36,
    coverImg: 'cReedence.jpg',
    category: 'Rock Clasico',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl:
      'https://craftrecordings.com/collections/creedence-clearwater-revival',
    youtube: 'https://www.youtube.com/channel/UC69yJGpLNIMk6_ECLwxBZwA',
    facebook: 'https://www.facebook.com/CCR',
    instagram: 'https://www.instagram.com/theofficialccr/',
    twitter: 'https://twitter.com/theofficialccr',
    spotify: 'https://open.spotify.com/artist/3IYUhFvPQItj6xySrBmZkd',
    deezer: 'https://www.deezer.com/mx/artist/2468',
    apple:
      'https://music.apple.com/mx/artist/creedence-clearwater-revival/2083606',
    soundcloud: 'https://soundcloud.com/creedence-clearwater-revival',
    tidal: 'https://listen.tidal.com/artist/36772',
    wiki: 'https://es.wikipedia.org/wiki/Creedence_Clearwater_Revival',
    ticketmaster:
      'https://www.ticketmaster.com/creedence-clearwater-revival-tribute-show-tickets/artist/2116810',
    tiktok:
      'https://www.tiktok.com/search/video?lang=es&q=creedence%20clearwater%20revival&t=1654383158590',
    napster: 'https://app.napster.com/artist/creedence-clearwater-revival',
  },
  {
    title: 'Ray Charles',
    description:
      'Ray Charles Robinson, más conocido como Ray Charles, fue un cantante, saxofonista y pianista estadounidense de soul y R&B. Entre sus amigos y músicos él prefería que le llamaran Hermano Ray y era frecuentemente referido como El Genio. Charles comenzó a perder la visión a la edad de 5 años y a los 7 años era ciego. Fue pionero de la música soul durante los años 1950 por la combinación del blues, rhythm and blues y el estilo gospel dentro de la música que grabó para el sello Atlantic. Contribuyó a la integración de la música country, rhythm and blues y música pop durante los años 1960 con su gran éxito en ABC Records, notable con sus dos álbumes de Modern Sound. Mientras estuvo con ABC, Ray Charles fue uno de los primeros músicos afroamericanos en tener control artístico en la compañía para la cual grababa.',
    videourl: 'https://youtube.com/playlist?list=PLF729174559CABBE2',
    price: '$50',
    coverImg: 'RayCharles.jpg',
    category: 'Jazz',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://raycharles.com/',
    youtube: 'https://www.youtube.com/channel/UCuDb3PN8JzDZLY-TUosFkgw',
    facebook: 'https://www.facebook.com/RayCharles',
    instagram: 'https://www.instagram.com/official.raycharles/',
    twitter: 'https://twitter.com/raycharles_tm',
    spotify: 'https://open.spotify.com/artist/1Mxqyy3pSjf8kZZL4QVxS0',
    deezer: 'https://www.deezer.com/mx/artist/1342',
    apple: 'https://music.apple.com/mx/artist/ray-charles/160926',
    soundcloud: 'https://soundcloud.com/raycharles',
    tidal: 'https://listen.tidal.com/artist/9572',
    wiki: 'https://es.wikipedia.org/wiki/Ray_Charles',
    ticketmaster:
      'https://www.ticketmaster.com/ray-charles-tickets/artist/857437',
    tiktok: 'https://www.tiktok.com/search?q=Ray%20Charles&t=1660884101665',
    napster: 'https://us.napster.com/artist/ray-charles',
  },

  {
    title: 'James Brown',
    description:
      'James Joseph Brown fue un cantante de soul y funk estadounidense. Progenitor de la música funk y una figura importante de la música y la danza del siglo XX, a menudo se le refiere por los apodos honoríficos Padrino del Soul, Sr. Dinamita y Hermano Soul No. 1',
    videourl:
      'https://youtube.com/playlist?list=PLho6UqUrhDcXJ1yQBwcbP2mAkhGFTmcFa',
    price: '$50',
    coverImg: 'JamesBrown.jpg',
    category: 'Soul',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'http://www.jamesbrown.com/',
    youtube: 'https://www.youtube.com/channel/UCOCZxe0gNRA7c3PGWPGoiGg',
    facebook: 'https://www.facebook.com/JamesBrown',
    instagram: 'https://www.instagram.com/jamesbrown/',
    twitter: 'https://twitter.com/JamesBrownsSoul',
    spotify: 'https://open.spotify.com/artist/7GaxyUddsPok8BuhxN6OUW',
    deezer: 'https://www.deezer.com/mx/artist/1622',
    apple: 'https://music.apple.com/mx/artist/james-brown/117118',
    soundcloud: 'https://soundcloud.com/james-brown-music',
    tidal: 'https://listen.tidal.com/artist/6361',
    wiki: 'https://es.wikipedia.org/wiki/James_Brown',
    ticketmaster:
      'https://www.ticketmaster.com/james-brown-tickets/artist/734650',
    tiktok: 'https://www.tiktok.com/@jamesbrown?lang=es',
    napster: 'https://us.napster.com/artist/james-brown',
  },
  {
    title: 'Johnny Cash',
    description:
      'Johnny Cash fue un cantante, compositor, músico, y actor estadounidense. Considerado como uno de los músicos más influyentes del siglo XX. Considerado el Rey de la Música Country y un icono de ese género. Cantautor de country, gospel, rock and roll y rockabilly, fue y es uno de los máximos representantes de la música country, aunque creó su propio subgénero musical..',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_mrvTv_4L8MD0vAZ5JBGUA8EbSm9KTRrZ8',
    price: '$50',
    coverImg: 'JohnnyCash.jpg',
    category: 'Rock Clasico',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.johnnycash.com/',
    youtube: 'https://www.youtube.com/channel/UCLwdOhL6TKbmjRtZ8wIr-Bg',
    facebook: 'https://www.facebook.com/johnnycash',
    instagram: 'https://www.instagram.com/johnnycash/',
    twitter: 'https://twitter.com/JohnnyCash',
    spotify: 'https://open.spotify.com/artist/6kACVPfCOnqzgfEF5ryl0x',
    deezer: 'https://www.deezer.com/mx/artist/405',
    apple: 'https://music.apple.com/mx/artist/johnny-cash/70936',
    soundcloud: 'https://soundcloud.com/johnnycash',
    tidal: 'https://listen.tidal.com/artist/156',
    wiki: 'https://es.wikipedia.org/wiki/Johnny_Cash',
    ticketmaster:
      'https://www.ticketmaster.com/johnny-cash-tribute-tickets/artist/1010893',
    tiktok: 'https://www.tiktok.com/@johnnycash?lang=es',
    napster: 'https://music.amazon.com/artists/B000QJO1ZA/johnny-cash',
  },
  {
    title: 'Chet Atkins',
    description:
      'Chester Burton "Chet" Atkins (20 de junio de 1924 – 30 de junio de 2001) fue un influyente guitarrista y productor de country. Su estilo —inspirado por Merle Travis, Django Reinhardt, George Barnes y Les Paul— le trajo admiradores dentro y fuera de la escena de EE. UU. Atkins produjo discos para Eddy Arnold, Don Gibson, Jim Reeves, Connie Smith, y Waylon Jennings. Creó, junto a Owen Bradley, el estilo de música country más suave y tranquilizador conocido como sonido Nashville, que llevó el gusto por la música country a admiradores de la música pop para adultos..',
    videourl:
      'https://youtube.com/playlist?list=PLF-cRDh2mrlWh3XECQmwAggRs2-WMnlr0&si=_ajc7XmACic8_bnW',
    price: 80,
    coverImg: 'chetatkins.jpeg',
    category: 'Country Guitar',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.misterguitar.us/',
    youtube: 'https://www.youtube.com/channel/UCBH-9udLbWZBO3gIrWnwIiw',
    facebook: 'https://www.facebook.com/chet.atkins.121',
    instagram: 'https://www.instagram.com/officialchetatkins/',
    twitter: 'https://twitter.com/chetatkins757',
    spotify: 'https://open.spotify.com/artist/4dZrt8Ong5t7YYpvbfp0RU',
    deezer: 'https://www.deezer.com/mx/artist/7635',
    apple: 'https://music.apple.com/mx/artist/chet-atkins/212083',
    soundcloud: 'https://soundcloud.com/chetatkinsofficial',
    tidal: 'https://listen.tidal.com/artist/137',
    wiki: 'https://es.wikipedia.org/wiki/Chet_Atkins',
    ticketmaster:
      'https://www.ticketmaster.com/chet-atkins-tickets/artist/732681',
    tiktok:
      'https://www.tiktok.com/search?lang=es&q=Chet%20Atkins&t=1693371013916',
    napster: 'https://music.amazon.com.mx/artists/B000QJO81C/chet-atkins',
  },
  {
    title: 'Neil Young',
    description:
      'Neil Young es un cantante y compositor de rock canadiense. En 1960 comenzó a actuar como artista en solitario en Canadá, antes de mudarse a California en 1966, donde cofundó la banda Buffalo Springfield junto con Stephen Stills y Richie Furay, y más tarde se unió a Crosby, Stills & Nash como cuarto miembro en 1969. Después de la ruptura de Buffalo Springfield, continuó como artista en solitario en 1968 y debutó con su álbum en solitario "Neil Young". Luego, se reunió con Crosby, Stills & Nash mientras seguía trabajando en su carrera en solitario. Los mejores álbumes de Young incluyen "Harvest", "Comes a Time" y "Silver & Gold"..',
    videourl:
      'https://youtube.com/playlist?list=PLoxhF9wWTB50GK0agk2Mdo7THMHdEM2M9&si=YpB0Rz7WXas3IqMK',
    price: '$50',
    coverImg: 'neilyoung.jpg',
    category: 'Rock Clasico',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://neilyoungarchives.com/',
    youtube: 'https://www.youtube.com/channel/UC8gbK8uoqKhlACSeenUPkFQ',
    facebook: 'https://www.facebook.com/NeilYoungRepriseRecords',
    instagram: 'https://www.instagram.com/neilyoung/',
    twitter: 'twitter.com/neilyoungnya',
    spotify: 'https://open.spotify.com/artist/6v8FB84lnmJs434UJf2Mrm',
    deezer: 'https://www.deezer.com/mx/artist/1379',
    apple: 'https://music.apple.com/mx/artist/neil-young/147370',
    soundcloud: 'https://soundcloud.com/neilyoungsoundcloud',
    tidal: 'https://listen.tidal.com/artist/205',
    wiki: 'https://es.wikipedia.org/wiki/Neil_Young',
    ticketmaster:
      'https://www.ticketmaster.com/neil-young-tickets/artist/736511',
    tiktok: 'https://www.tiktok.com/@neilyoungofficial?lang=es',
    napster: 'https://music.amazon.com/artists/B000QJJOTI/neil-young',
  },
  {
    title: 'Gary Moore',
    description:
      'Robert William Gary Moore (Belfast, 4 de abril de 1952-Estepona, 6 de febrero de 2011) fue un músico, compositor y productor británico de blues rock y hard rock, conocido mayormente por haber sido guitarrista líder en bandas como Skid Row, Colosseum II y Thin Lizzy, y por su extensa carrera en solitario como vocalista.Influenciado por guitarristas de blues y rock, a los diez años comenzó a practicar la guitarra de manera autodidacta con el apoyo principalmente de su padre.',
    videourl: 'https://youtube.com/playlist?list=UUMJAJsdnpJjwm0FRDHaRCmg',
    price: '$50',
    coverImg: 'moore.jpg',
    category: 'Rock Clasico',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'http://www.gary-moore.com/',
    youtube: 'https://www.youtube.com/channel/UCr-aRVjmQhYEn08AxRkmgBw',
    facebook: 'https://www.facebook.com/GaryMooreRW',
    instagram: 'https://www.instagram.com/gary_moore_page/',
    twitter: 'https://twitter.com/gmooreofficial',
    spotify:
      'https://open.spotify.com/artist/23wr9RJZg0PmYvVFyNkQ4j?autoplay=true',
    deezer: 'https://www.deezer.com/mx/artist/666',
    apple: 'https://music.apple.com/us/artist/gary-moore/519368',
    soundcloud: 'https://soundcloud.com/gary-moore-official',
    tidal: 'https://listen.tidal.com/artist/10243',
    wiki: 'https://es.wikipedia.org/wiki/Gary_Moore',
    ticketmaster:
      'https://www.ticketmaster.com/gary-moore-tickets/artist/34065',
    tiktok:
      'https://www.tiktok.com/search?lang=es&q=gary%20moore&t=1684364347315',
    napster: 'https://music.amazon.com.mx/artists/B000QKSIQ2/gary-moore',
  },
  {
    title: 'Bob Dylan',
    description:
      'Bob Dylan (Duluth, Minesota; 24 de mayo de 1941), registrado al nacer como Robert Allen Zimmerman, es un músico, compositor, cantante y poeta estadounidense, ampliamente considerado como una de las figuras más prolíficas e influyentes en la música popular del siglo XX y de comienzos del siglo XXI. En 2016 recibió el Premio Nobel de Literatura..',
    videourl:
      'https://youtube.com/playlist?list=PLpTHjAucqq9A2IDIsR8Ftbg7U6bKCMrcr',
    price: '$50',
    coverImg: 'BobDylan.jpg',
    category: 'Rock Clasico',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.bobdylan.com/',
    youtube: 'https://www.youtube.com/channel/UCnRI0ay61tY-fKYzzB3fCnw',
    facebook: 'https://www.facebook.com/bobdylan',
    instagram: 'https://www.instagram.com/bobdylan/',
    twitter: 'https://twitter.com/bobdylan',
    spotify: 'https://open.spotify.com/artist/74ASZWbe4lXaubB36ztrGX',
    deezer: 'https://www.deezer.com/mx/artist/808',
    apple: 'https://music.apple.com/mx/artist/bob-dylan/462006',
    soundcloud: 'https://soundcloud.com/bobdylan',
    tidal: 'https://listen.tidal.com/artist/80',
    wiki: 'https://es.wikipedia.org/wiki/Bob_Dylan',
    ticketmaster:
      'https://www.ticketmaster.com/bob-dylan-tickets/artist/734972',
    tiktok: 'https://www.tiktok.com/@bobdylan004?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B000QJHUZ8/bob-dylan',
  },
  {
    title: 'Eric Clapton',
    description:
      'Eric Patrick Clapton, más conocido como Eric Clapton, es un guitarrista, cantante y compositor de rock y blues británico, conocido por su magistral habilidad con la guitarra eléctrica, en concreto con su Stratocaster. Apodado Slowhand, desde su época en The Yardbirds, y God en su época con Cream, es miembro del Salón de la Fama del Rock and Roll por partida triple: como miembro de The Yardbirds y de Cream y por su carrera como solista. En opinión de muchos críticos, ha sido uno de los artistas de la cultura de masas más respetados e influyentes de todos los tiempos. Aparece en el puesto número 2 de la lista "Los 100 guitarristas más grandes de todos los tiempos" de la revista Rolling Stone y en el puesto número 55 de su especial "Inmortales: Los 100 artistas más grandes de todos los tiempos". Además, en 2005 la revista Guitar World incluyó cinco de sus canciones entre los mejores solos de guitarra de todos los tiempos..',
    videourl:
      'https://youtube.com/playlist?list=PLABGggHhsbEeaRtdzqnxYoEINsJE_4GF4',
    price: 36,
    coverImg: 'EricClapton.jpg',
    category: 'Rock',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.ericclapton.com/',
    youtube: 'https://www.youtube.com/channel/UCtCOFqqGGGunX71nfZgPQOQ',
    facebook: 'https://www.facebook.com/ericclapton',
    instagram: 'https://www.instagram.com/ericclapton/',
    twitter: 'https://twitter.com/ericclapton',
    spotify: 'https://open.spotify.com/artist/6PAt558ZEZl0DmdXlnjMgD',
    deezer: 'https://www.deezer.com/mx/artist/192',
    apple: 'https://music.apple.com/mx/artist/eric-clapton/123853',
    soundcloud: 'https://soundcloud.com/ericclapton',
    tidal: 'https://listen.tidal.com/artist/124',
    wiki: 'https://es.wikipedia.org/wiki/Eric_Clapton',
    ticketmaster:
      'https://www.ticketmaster.com/eric-clapton-tickets/artist/768018',
    tiktok:
      'https://www.tiktok.com/search?lang=es&q=Eric%20Clapton&t=1654206199081',
    napster: 'https://music.amazon.com.mx/artists/B000QJNLKQ/eric-clapton',
  },
  {
    title: 'ZZ Top',
    description:
      'ZZ Top es una banda estadounidense de blues rock y hard rock formada en 1969 en Houston, Texas. Este power trio lo conforman Billy Gibbons en la guitarra y la voz, Dusty Hill en el bajo, los teclados y la voz, y Frank Beard en la batería. Tienen el mérito de ser uno de los pocos grupos de rock que todavía conservaba sus miembros originales después de cincuenta años (hasta la muerte de Hill en el 2021), y además de haber mantenido un número casi similar de años al mismo mánager y productor, Bill Ham. Popularmente son conocidos gracias a su particular estilo, principalmente de Gibbons y Hill, quienes siempre son retratados llevando gafas de sol, ropa parecida o en ocasiones idéntica y larguísimas barbas.',
    videourl:
      'http://youtube.com/playlist?list=OLAK5uy_nAyGxqbVYjnQsA2I7bXrGedq7V5BHBu0w',
    price: 80,
    coverImg: 'zztop.jpg',
    category: 'Rock Clasico',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.zztop.com/',
    youtube: 'https://www.youtube.com/channel/UCXdqh7TtuMuqasECfTItzXA',
    facebook: 'https://www.facebook.com/ZZTop/',
    instagram: 'https://www.instagram.com/zztop/',
    twitter: 'https://twitter.com/ZZTop',
    spotify: 'https://open.spotify.com/artist/2AM4ilv6UzW0uMRuqKtDgN',
    deezer: 'https://www.deezer.com/search/zz%20top',
    apple: 'https://music.apple.com/mx/artist/zz-top/215917',
    soundcloud: 'https://soundcloud.com/zztopmusic',
    tidal: 'https://listen.tidal.com/artist/3842',
    wiki: 'https://es.wikipedia.org/wiki/ZZ_Top',
    ticketmaster: 'https://www.ticketmaster.com/zz-top-tickets/artist/736523',
    tiktok: 'https://www.tiktok.com/@zztopofficial?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B000QJP88O/zz-top',
  },
  {
    title: 'John Lee Hooker',
    description:
      'John Lee Hooker (Clarksdale, Misisipi; 22 de agosto de 19121​-Los Altos, California; 21 de junio de 2001) fue un cantante y guitarrista de blues estadounidense. Hijo de un aparcero, saltó a la fama interpretando una adaptación al estilo de la guitarra eléctrica del Delta blues que desarrolló en Detroit. Hooker incorporó a menudo otros elementos, como el talking blues y el hill country blues del norte de Mississippi. Desarrolló su propio estilo de boogie rítmico, distinto del boogie-woogie derivado del piano de los años 1930-1940. Hooker ocupó el puesto 35 en la lista de 2015 de los 100 mejores guitarristas de Rolling Stone.Algunas de sus canciones más conocidas son "Boogie Chillen" (1948), "Crawling King Snake" (1949), "Dimples" (1956), "Boom Boom" (1962) y "One Bourbon, One Scotch, One Beer" (1966). Varios de sus últimos álbumes, como The Healer (1989), Mr. Lucky (1991), Chill Out (1995), y Dont Look Back (1997), fueron éxitos en las listas de álbumes de Estados Unidos y el Reino Unido. The Healer (por la canción "Im in the Mood") y Chill Out (por el álbum) le valieron sendos premios Grammy,​ así como Dont Look Back, que le valió un doble Grammy a la Mejor Grabación de Blues Tradicional y a la Mejor Colaboración Pop con Voces (con Van Morrison).',
    videourl:
      'https://youtube.com/playlist?list=PLA5o1j-uDqwTaDMxv1Gr4b9nENzfFdemv&si=DIw_D47u--7KWS25',
    price: 80,
    coverImg: 'johnleehooker.jpg',
    category: 'Blues',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://johnleehooker.com/',
    youtube: 'https://www.youtube.com/johnleehookerofficial',
    facebook: 'https://www.facebook.com/JohnLeeHookerofficial',
    instagram:'https://www.instagram.com/johnleehookerofficial/',
    twitter: 'https://x.com/johnleehooker',
    spotify: 'https://open.spotify.com/artist/1yNOfXGQNGjAynk77wv85x',
    deezer: 'https://www.deezer.com/us/artist/1159',
    apple: 'https://music.apple.com/us/artist/john-lee-hooker/97536',
    soundcloud: 'https://soundcloud.com/johnleehooker',
    tidal: 'https://listen.tidal.com/artist/14835',
    wiki: 'https://es.wikipedia.org/wiki/John_Lee_Hooker',
    ticketmaster: 'https://www.ticketmaster.com/',
    tiktok: 'https://www.tiktok.com/@johnleehooker',
    napster: 'https://music.amazon.com.mx/artists/B000QJPFM8/john-lee-hooker',
  },
  {
    title: 'Gary B.B Coleman',
    description:
      'Gary B.B. Coleman (1 de enero de 1947 - 21 de febrero de 1994) fue un guitarrista, cantante, compositor y productor discográfico de soul blues estadounidense.Originalmente un músico local que se convirtió en promotor de blues y músico de sesión, Coleman grabó su álbum debut en 1986, que fue relanzado por Ichiban Records. Publicó varios otros álbumes y produjo la mayor parte del catálogo de blues de Ichiban hasta su muerte en 1994. En muchas ocasiones, Coleman asumió tareas como multiinstrumentista en el estudio de grabación. Reconoció la influencia tanto de B.B. King, con su apodo B.B., como de un compatriota texano, Freddie King.Coleman nació en Paris, Texas. A los 15 años ya estaba trabajando junto a Freddie King. Más tarde, apoyó a Lightnin Hopkins en concierto y formó su propio grupo. Durante este tiempo, comenzó a organizar presentaciones simultáneamente en clubes nocturnos de tres estados Texas, Oklahoma y Colorado. Este estilo de vida dual en el suroeste de Estados Unidos continuó durante casi veinte años.En 1985, creó su propio sello discográfico independiente, Mr. B Records, y lanzó un sencillo, "One Eyed Woman", además de su álbum debut, Nothin but the Blues, al año siguiente. El álbum resultó ser popular, lo que llevó a Ichiban Records a firmar un contrato de grabación con Coleman y relanzar Nothin but the Blues bajo su sello en 1987.',
    videourl:
      'https://youtube.com/playlist?list=PLF-cRDh2mrlX7ROlrzXfdgrJJ2xTyMNUw&si=CfVg9-EpcDY6v26s',
    price: 80,
    coverImg: 'garycoleman.jpeg',
    category: 'Blues',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.last.fm/music/Gary+B.B.+Coleman/+albums',
    youtube: 'https://www.youtube.com/channel/UCjfpGapynoAr6sxgaDxX9UQ',
    facebook: 'https://www.facebook.com/search/top?q=gary%20bb%20coleman',
    instagram: 'https://www.instagram.com/gary_b._b._coleman/',
    twitter: 'https://x.com/search?q=Gary%20BB%20Coleman&src=typeahead_click&f=live',
    spotify: 'https://open.spotify.com/artist/5nuZA3EivM4ftxQu4m2PEJ?autoplay=true',
    deezer: 'https://www.deezer.com/us/artist/372518',
    apple: 'https://music.apple.com/mx/artist/gary-b-b-coleman/64862181',
    soundcloud: 'https://soundcloud.com/garybbcoleman',
    tidal: 'https://listen.tidal.com/artist/4267418',
    wiki: 'https://en.wikipedia.org/wiki/Gary_B.B._Coleman',
    ticketmaster: 'https://www.ticketmaster.com/',
    tiktok: 'https://www.tiktok.com/search?lang=es&q=gary%20bb%20coleman&t=1733509873729',
    napster: 'https://music.amazon.com.mx/artists/B000S2A9K6/gary-b-b-coleman',
  },
  {
    title: 'BB.King',
    description:
      'Riley B. King o Riley Ben King (Itta Bena, Misisipi, 16 de septiembre de 1925–Las Vegas, 14 de mayo de 2015), más conocido como B.B. King, fue un músico, cantante y compositor estadounidense. Es ampliamente considerado uno de los músicos de blues más influyentes de todos los tiempos, recibiendo el apodo de «Rey del Blues» y el sobrenombre de «uno de los tres reyes (kings) de la guitarra blues» junto a Albert King y Freddie King. Según Edward M. Komara, King «introdujo un sofisticado estilo de solos basados en fluidas cuerdas [de guitarra] dobladas y brillantes vibratos que influirían prácticamente en todos los guitarristas de blues eléctrico que le siguieron». King tuvo una gran influencia en el aspecto musical y social sobre el cantante Elvis Presley habiéndose conocido y desarrollado una amistad en los pubs de Beale Street en Memphis a principios de los años 50s..',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_lWdvjbt80dEGQcJclpzRpIECAwGgV6IdY',
    price: '$50',
    coverImg: 'BbKing.jpg',
    category: 'Blues',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.bbking.com/',
    youtube: 'https://www.youtube.com/channel/UCRBh8rjd8umQiMMNB9_5D_w',
    facebook: 'https://www.facebook.com/bbking',
    instagram: 'https://www.instagram.com/bbkingofficial/',
    twitter: 'https://twitter.com/BBKingMuseum',
    spotify: 'https://open.spotify.com/artist/5xLSa7l4IV1gsQfhAMvl0U',
    deezer: 'https://www.deezer.com/mx/artist/3614',
    apple: 'https://music.apple.com/mx/artist/b-b-king/75207',
    soundcloud: 'https://soundcloud.com/bb-king-official',
    tidal: 'https://listen.tidal.com/artist/5307',
    wiki: 'https://es.wikipedia.org/wiki/B._B._King',
    ticketmaster: 'https://www.ticketmaster.com/bb-king-tickets/artist/769912',
    tiktok: 'https://www.tiktok.com/search?lang=es&q=bb%20king&t=1654464410604',
    napster: 'https://music.amazon.com.mx/artists/B000QJNO1W',
  },
]

export default CardData18
