export const MenuItems = [
    {
      title: 'Promocion Musical',
      path: '/about',
      cName: 'dropdown-link'
    },
    {
      title: 'Venta de Boletos',
      path: "/tickets",
      cName:'dropdown-link'
    },
    {
      title: 'Tours',
      path: '/tours',
      cName: 'dropdown-link'
    },
    {
      title: 'Venta de Mercancia Oficial',
      path: '/merch',
      cName: 'dropdown-link'
    },
    {
      title: 'Publicidad',
      path: '/bibliography',
      cName: 'dropdown-link'
    },
    {
        title: 'Video Comerciales',
        path: '/bibliography',
        cName: 'dropdown-link'
      }
  ];