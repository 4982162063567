import React from 'react'

const Telefono = () => {
  return (
    <div className="channel-container">
    <h1 className="tele-boletinera"> Telefono</h1>
    <p className="tele-boletinera">52+3324949195</p>
    </div>
  )
}

export default Telefono