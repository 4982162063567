import Navbar from './Navbar/Navbar';
import Particle from './Particle';
import ButtonTreeCategories from './ButtonTreeCategories';


import Footer from './Footer/Footer';

function Categories() {
  return (
    <>
      <Navbar />
      <Particle id="particles" />
      <main className="categories-main">
        <h1 className="title2">Bienvenido</h1>
        <h4 className="headertext">Escoge la Música que te Gusta</h4>
        <ButtonTreeCategories />
        <div className="image-gallery">
          <img className="indianaut" src="./images/aguiladorada.jpeg" alt="Águila dorada" />
          <img className="indianaut2" src="./images/aguiladorada.jpeg" alt="Águila dorada" />
          <img className="indianaut2" src="./images/moonaut.png" alt="Astronaut on the moon" />
          <img className="indianaut" src="./images/astronaut.png" alt="Astronaut" />
          <img className="indianaut" src="./images/indianaut.png" alt="Indian astronaut" />
          <img className="indianaut" src="./images/rockguitarist.jpg" alt="Rock guitarist" />
        </div>
        
       
      </main>
      <Footer />
    </>
  );
}

export default Categories;
