import Particle from './Particle'
import Navbar from './Navbar/Navbar'
import { Link } from 'react-router-dom'
import { IoTicketSharp } from "react-icons/io5";




function EventTreeCategories2() {
  return (
    <div>
    <Navbar/>
    <Particle id='particles'/>
    <h1 className="title2">Guadalajara y Zapopan</h1>
    <h1 className="title2">Escoje el Foro o sala de Concierto cual deseas atender</h1>
    <div className="sponsorcontainer">
    <a 
    href="https://www.teatrodiana.com/" 
    className="btn" 
    target="_blank" 
    rel="noopener noreferrer"
  >
    <img
      src="../images/TeatroDiana.png"
      className="sponsor"
      alt="Teatro Diana"
    />
  </a>

  <a 
  href="https://www.c3stage.com/calendario-de-eventos/" 
  className="btn" 
  target="_blank" 
  rel="noopener noreferrer"
>
  <img
    src="../images/Ctres.jpg"
    className="sponsor"
    alt="C3 Stage"
  />
</a>

<a 
href="https://www.auditorio-telmex.com/" 
className="btn" 
target="_blank" 
rel="noopener noreferrer"
>
<img
  src="../images/telmex.jpg"
  className="sponsor"
  alt="Auditorio Telmex"
/>
</a>


<a 
href="https://estadioakron.mx/Calendario" 
className="btn" 
target="_blank" 
rel="noopener noreferrer"
>
<img
  src="../images/akron.jpg"
  className="sponsor"
  alt="Estadio Akron"
/>
</a>

<a
href="https://www.ticketmaster.com.mx/estadio-3-de-marzo-u-a-g-boletos-zapopan/venue/499778"
className="btn"
target="_blank" 
rel="noopener noreferrer"
>
<img
  src="../images/tresdemarzo.jpg"
  className="sponsor"
  alt="Estadio 3 de Marzo"
/>
</a>
      <a href="https://eventos.ticketnowmexico.com/performancelisting.asp?venuename=PABELL%C3%93N+CULTURAL+UNIVERSITARIO&venue=250"
         className="btn" to="/pabellonudg">
        <img
          src="../images/pabellonudg.jpeg"
          className="sponsor"
          alt=""
          media="(max-width: 400px)"
        />
      </a>
<a 
  href="https://baramericas.com.mx/calendar/" 
  className="btn" 
  target="_blank" 
  rel="noopener noreferrer"
>
  <img
    src="../images/americasbar.png"
    className="sponsor"
    alt="Bar Americas"
  />
</a>
<a 
href="https://www.facebook.com/hliveclubshow/events" 
className="btn" 
target="_blank" 
rel="noopener noreferrer"
>
<img
  src="../images/livemusic.jpg"
  className="sponsor"
  alt="Live Music Events"
/>
</a>
<a 
href="https://www.arenavfg.mx/eventos/" 
className="btn" 
target="_blank" 
rel="noopener noreferrer"
>
<img
  src="../images/arenavfg.jpg"
  className="sponsor"
  alt="Arena VFG"
/>
</a>

<a 
href="https://www.soluticket.mx/event/retrobandafest2024" 
className="btn" 
target="_blank" 
rel="noopener noreferrer"
>
<img
  src="../images/crowneventos.jpg"
  className="sponsor"
  alt="Arena VFG"
/>
</a>

      
    </div>
    <Link 
    className="btn" 
    to="/tickets" 
    style={{
      display: 'block',
      textAlign: 'center',
      width: '100%',  // Ensure it spans full width
      padding: '10px 0', // Adjust padding for better layout
    }}
  >
    <button>
      Para Buscar  Boletos <IoTicketSharp/>  para Eventos en Guadalajara Presiona Aqui !!!
    </button>
  </Link>
    </div>
  
  )
}

export default EventTreeCategories2