export const MenuItems2 = [
  {
    href: "https://www.teatrodiana.com/",
    imgSrc: "../images/TeatroDiana.png",
    alt: "Teatro Diana",
  },
  {
    href: "https://www.c3stage.com/calendario-de-eventos/",
    imgSrc: "../images/Ctres.jpg",
    alt: "C3 Stage",
  },
  {
    href: "https://www.auditorio-telmex.com/",
    imgSrc: "../images/telmex.jpg",
    alt: "Auditorio Telmex",
  },
  {
    href: "https://estadioakron.mx/Calendario",
    imgSrc: "../images/akron.jpg",
    alt: "Estadio Akron",
  },
  {
    href: "https://www.ticketmaster.com.mx/estadio-3-de-marzo-u-a-g-boletos-zapopan/venue/499778",
    imgSrc: "../images/tresdemarzo.jpg",
    alt: "Estadio 3 de Marzo",
  },
  {
    href: "https://eventos.ticketnowmexico.com/performancelisting.asp?venuename=PABELL%C3%93N+CULTURAL+UNIVERSITARIO&venue=250",
    imgSrc: "../images/pabellonudg.jpeg",
    alt: "Pabellón UDG",
  },
  {
    href: "https://baramericas.com.mx/calendar/",
    imgSrc: "../images/americasbar.png",
    alt: "Bar Americas",
  },
  {
    href: "https://www.facebook.com/hliveclubshow/events",
    imgSrc: "../images/livemusic.jpg",
    alt: "Live Music Events",
  },
  {
    href: "https://www.arenavfg.mx/eventos/",
    imgSrc: "../images/arenavfg.jpg",
    alt: "Arena VFG",
  },
  {
    href: "https://www.soluticket.mx/event/retrobandafest2024",
    imgSrc: "../images/crowneventos.jpg",
    alt: "Crown Eventos",
  },
];