

function SponsorContainer() {
  return (
    <div><div className="sponsor1container">
           <a href="https://www.viagogo.com.mx/" target="_blank" rel="noreferrer">
          <img src="../images/viagogo.jpeg" className="sponsor1" alt="" media="(max-width: 400px)" />
      </a>
       <a href="https://www.ontourviajes.com/" target="_blank" rel="noreferrer">
          <img src="../images/ontour.png" className="sponsor1" alt="" media="(max-width: 400px)" />
      </a>
      <a href="https://malaracha.com/" target="_blank" rel="noreferrer">
          <img src="../images/malaracha.png" className="sponsor1" alt="" media="(max-width: 400px)" />
        </a>
      
         <a href="https://www.casaorendain.com/product/tequila-ollitas/33?idioma=es" target="_blank" rel="noreferrer" >
      <img src="../images/ollitas2.jpeg" className="sponsor1"   alt="" media="(max-width: 400px)" />
      </a>

      <a href="https://tecate.com/codigotecate/agegate" target="_blank" rel="noreferrer">
      <img src="../images/tecate.jpg" className="sponsor1" alt=""  media="(max-width: 400px)" />
      </a>

      <a href="https://www.maskkingmexico.com/" target="_blank" rel="noreferrer" >
      <img src="../images/masking.png" className="sponsor1" alt=""  media="(max-width: 400px)" />
        </a>

           <a href="https://www.caliente.mx/ofertas/apuestas-deportivas/" target="_blank" rel="noreferrer" >
        <img src="../images/caliente.jpg" className="sponsor1"  alt="" media="(max-width: 400px)" />
      </a>
      
        <a href="https://www.rollingstone.com/" target="_blank" rel="noreferrer">
        <img src="../images/rollingstone.jpg" className="sponsor1"  alt="" media="(max-width: 400px)" />
        </a>
       <a href="https://cerveceriachapultepec.com/" target="_blank" rel="noreferrer">
        <img src="../images/chapulogo.jpg" className="sponsor1"   alt="" media="(max-width: 400px)" />
        </a>
        
         <a href="https://www.indierocks.mx/tv/" target="_blank" rel="noreferrer">
        <img src="../images/indierocks.jpg" className="sponsor1"  alt="" media="(max-width: 400px)" />
        </a>
         
      <a href="https://www.billboard.com/charts/hot-100/  " target="_blank" rel="noreferrer">
        <img src="../images/billboard.jpeg" className="sponsor1"  alt="" media="(max-width: 400px)" />
        </a>
         <a href="https://www.sonymusic.com.mx/" target="_blank" rel="noreferrer">
        <img src="../images/sonymusic.jpeg" className="sponsor1"  alt="" media="(max-width: 400px)" />
        </a>
        <a href="https://www.warnermusic.com.mx/" target="_blank" rel="noreferrer">
        <img src="../images/warner.jpeg" className="sponsor1"  alt="" media="(max-width: 400px)" />
        </a>
        <a href="https://umusicstore.com.mx/" target="_blank" rel="noreferrer">
          <img src="../images/universal.jpeg" className="sponsor1" alt="" media="(max-width: 400px)" />
          </a>
          <a href="https://www.ocesa.com.mx/" target="_blank" rel="noreferrer">
          <img src="../images/ocesa.png" className="sponsor1" alt="" media="(max-width: 400px)" />
        </a> 
        <a href="https://www.banamex.com/" target="_blank" rel="noreferrer">
          <img src="../images/citi.jpg" className="sponsor1" alt="" media="(max-width: 400px)" />
        </a> 
        
       <a href="http://www.auditorio-telmex.com/" target="_blank" rel="noreferrer">
        <img src="../images/telmex.jpg" className="sponsor1" alt="" media="(max-width: 400px)" />
      </a>
        <a href="https://www.arenavfg.mx/" target="_blank" rel="noreferrer">
          <img src="../images/arenavfg.jpg" className="sponsor1" alt="" media="(max-width: 400px)" />
      </a> 
      <a href="https://www.cervezaminerva.mx/home" target="_blank" rel="noreferrer">
          <img src="../images/minerva.jpg" className="sponsor1" alt="" media="(max-width: 400px)" />
        </a>
        <a href="https://www.coca-cola.com.mx/" target="_blank" rel="noreferrer">
          <img src="../images/cocacola.jpg" className="sponsor1" alt="" media="(max-width: 400px)" />
      </a>
      <a href="https://www.teatrodiana.com/" target="_blank" rel="noreferrer">
      <img
        src="../images/TeatroDiana.png"
        className="sponsor1"
        alt=""
        media="(max-width: 400px)"
      />
    </a>
    <a href="https://www.coca-cola.com.mx/" target="_blank" rel="noreferrer">
    <img src="../images/cocacola.jpg" className="sponsor1" alt="" media="(max-width: 400px)" />
</a>
<a href="https://www.teatrodiana.com/" target="_blank" rel="noreferrer">
<img
  src="../images/TeatroDiana.png"
  className="sponsor1"
  alt=""
  media="(max-width: 400px)"
/>
</a>
<a href="https://www.coca-cola.com.mx/" target="_blank" rel="noreferrer">
<img src="../images/cocacola.jpg" className="sponsor1" alt="" media="(max-width: 400px)" />
</a>
<a href="https://www.teatrodiana.com/" target="_blank" rel="noreferrer">
<img
src="../images/TeatroDiana.png"
className="sponsor1"
alt=""
media="(max-width: 400px)"
/>
</a>
<a href="https://www.coca-cola.com.mx/" target="_blank" rel="noreferrer">
<img src="../images/cocacola.jpg" className="sponsor1" alt="" media="(max-width: 400px)" />
</a>
<a href="https://www.teatrodiana.com/" target="_blank" rel="noreferrer">
<img
src="../images/TeatroDiana.png"
className="sponsor1"
alt=""
media="(max-width: 400px)"
/>
</a>
<a href="https://www.coca-cola.com.mx/" target="_blank" rel="noreferrer">
<img src="../images/cocacola.jpg" className="sponsor1" alt="" media="(max-width: 400px)" />
</a>
<a href="https://www.teatrodiana.com/" target="_blank" rel="noreferrer">
<img
src="../images/TeatroDiana.png"
className="sponsor1"
alt=""
media="(max-width: 400px)"
/>
</a>
<a
        href="https://www.bancoazteca.com.mx/"
        target="_blank"
        rel="noreferrer"
      >
        <img
          src="../images/bancoazteca.jpeg"
          className="sponsor1"
          alt=""
          media="(max-width: 400px)"
        />
      </a>
      <a
        href="https://www.bancoazteca.com.mx/"
        target="_blank"
        rel="noreferrer"
      >
        <img
          src="../images/bancoazteca.jpeg"
          className="sponsor1"
          alt=""
          media="(max-width: 400px)"
        />
      </a>
      <a
        href="https://www.bancoazteca.com.mx/"
        target="_blank"
        rel="noreferrer"
      >
        <img
          src="../images/bancoazteca.jpeg"
          className="sponsor1"
          alt=""
          media="(max-width: 400px)"
        />
      </a>
    </div>
    </div>
  )
}

export default SponsorContainer