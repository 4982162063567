const CardData17=[
    {
        title: 'Juan Luis Guerra',
        description:
          'Juan Luis Guerra Seijas (Ciudad Trujillo, 7 de junio de 1957) es un cantautor, arreglista, músico, productor musical y empresario dominicano. Con más de 40 años de carrera, ha vendido más de 30 millones de discos1​2​3​ y ganado numerosos premios, incluidos 27 Grammy Latinos, dos Grammy estadounidenses y once Premios Latin Billboard;4​ fue honrado como Icono de BMI en la 13.ª edición de los Premios Latinos de BMI, nombrado Compositor Latino del Año de 1995 de BMI, las composiciones de Guerra han ganado 14 Premios Latinos de BMI.',
        videourl:
          'https://youtube.com/playlist?list=OLAK5uy_leZQSTI4LI2dIGVBATGLuif1aIS-pjvoc&si=clraczIF5G2z1yAb',
        price: '$50',
        coverImg: 'juanluisguerra.jpg',
        category: 'Bachatta',
        location: 'Republica Dominicana',
        locationImg: 'dominican.jpg',
        openSpots: null,
        likes: 1000,
        websiteurl: 'https://juanluisguerra.com/',
        youtube: 'https://www.youtube.com/channel/UColz1FbIlymB0lfzhESxtow',
        facebook: 'https://www.facebook.com/juanluisguerra440/',
        instagram: 'https://www.instagram.com/juanluisguerra/',
        twitter: 'https://x.com/JuanLuisGuerra/',
        spotify: 'https://open.spotify.com/artist/3nlpTZci9O5W8RsNoNH559',
        deezer: 'https://www.deezer.com/mx/artist/4527779',
        apple: 'https://music.apple.com/us/artist/juan-luis-guerra/6786499',
        soundcloud: 'https://soundcloud.com/juan-luis-guerra-official',
        tidal: 'https://listen.tidal.com/artist/10564',
        wiki: 'https://es.wikipedia.org/wiki/Juan_Luis_Guerra',
        ticketmaster:
          'https://www.ticketmaster.com/juan-luis-guerra-tickets/artist/769172',
        tiktok: 'https://www.tiktok.com/@juanluisguerra',
        napster: 'https://music.amazon.com/artists/B000QJHP5I/juan-luis-guerra-4-40',
      },
      {
        title: 'Gera MX',
        description:
          'Gerardo Daniel Torres Montante (San Nicolás de los Garza, Nuevo León; 15 de julio de 1994 ), mejor conocido por su nombr MX (anteriormente Gera MXM), es un rapero y compositor mexicano. Su nombre artístico proviene de las primeras cuatro letras de su nombre real, Gera (Gerardo) y la abreviatura del sello Mexamafia ( MXM ), la abreviatura MX proviene de México.e artístico Gera.',
        videourl:
          'https://youtube.com/playlist?list=OLAK5uy_nKCRl-R8dhnNTcmpHzJ5XiKzLseTEaij4',
        price: '$50',
        coverImg: 'gera.jpg',
        category: 'Rap Latino',
        location: 'Mexico',
        locationImg: 'mexiflag.jpg',
        openSpots: null,
        likes: 1000,
        websiteurl: 'https://richvagosent.com/',
        youtube: 'https://www.youtube.com/watch?v=ZaJZ9GJwz30',
        facebook: 'https://www.facebook.com/GERAMX444/',
        instagram: 'https://www.instagram.com/geramx1/',
        twitter: 'https://twitter.com/mxgeramx',
        spotify: 'https://open.spotify.com/artist/2hejA1Dkf8v8R0koF44FvW',
        deezer: 'https://www.deezer.com/mx/artist/6661704',
        apple: 'https://music.apple.com/mx/artist/gera-mx/863348914',
        soundcloud: 'https://soundcloud.com/geramx-music',
        tidal: 'https://listen.tidal.com/artist/8511790',
        wiki: 'https://es.wikipedia.org/wiki/Gera_MX',
        ticketmaster:
          'https://www.ticketmaster.com./gera-mx-tickets/artist/2773162',
        tiktok: 'https://www.tiktok.com/@geramxfans.official?lang=es',
        napster: 'https://music.amazon.com.mx/artists/B079V2DR9G/gera-mx',
      },
      {
        title: 'Gloria Trevi',
        description:
          ' Gloria de los Ángeles Treviño Ruiz (Monterrey, Nuevo León, 15 de febrero de 1968), conocida artísticamente como Gloria Trevi, es una cantante, actriz, productora, compositora y empresaria mexicana.En 1985 se mudó a la Ciudad de México para comenzar su carrera artística. Allí conoció al productor y arreglista Sergio Andrade, quien formó el grupo musical "Boquitas Pintadas". Cuatro años más tarde, de la mano de Andrade, publicó su álbum debut ...Qué hago aquí? , distribuido por BMG Ariola. En los 90, debutó en el cine con tres películas: Pelo suelto (1991), Zapatos viejos (1993) y Una papa sin catsup (1995). Terminó la década con cinco álbumes que sumaban casi 5 millones de ventas.',
        videourl:
          'https://youtube.com/playlist?list=PLszZ23pLzkUvIXPmh_x5TGeVpTazENODe',
        price: '$50',
        coverImg: 'trevi.jpg',
        category: 'Regional Latino',
        location: 'Mexico',
        locationImg: 'mexiflag.jpg',
        openSpots: null,
        likes: 1000,
        websiteurl: 'https://gloriatrevi.lnk.to/IslaDivinaAlbum!YTD',
        youtube: 'https://www.youtube.com/channel/UCNZazBoobtvziOGR65QQVog',
        facebook: 'https://www.facebook.com/GloriaTreviOficial',
        instagram: 'https://www.instagram.com/gloriatrevi/',
        twitter: 'https://twitter.com/GloriaTrevi',
        spotify: 'https://open.spotify.com/artist/1Db5GsIoVWYktPoD2nnPZZ',
        deezer: 'https://www.deezer.com/mx/artist/7218',
        apple: 'https://music.apple.com/mx/artist/gloria-trevi/6711586',
        soundcloud: 'https://soundcloud.com/gloria-trevi-official',
        tidal: 'https://listen.tidal.com/artist/3752902',
        wiki: 'https://es.wikipedia.org/wiki/Gloria_Trevi',
        ticketmaster:
          'https://www.ticketmaster.com/gloria-trevi-tickets/artist/956252',
        tiktok: 'https://www.tiktok.com/@gloriatrevioficial',
        napster: 'https://music.amazon.com.mx/artists/B00130DI78/gloria-trevi',
      },
      {
        title: 'Eden Muñoz',
        description:
          'Rodolfo Edén Muñoz Cantú (Los Mochis, Sinaloa, 25 de septiembre de 1990), es un cantante, compositor y productor mexicano de música regional mexicana. Muñoz es el menor de 4 hermanos del matrimonio entre Rodolfo Muñoz e Isidora Cantú quienes por azares del destino la vida los traslada a la ciudad de Mazatlán, Sinaloa, donde más adelante su crianza y entorno lo llevaría de lleno a la música.Desde muy pequeño Edén comenzó a dar muestra que había nacido para esto, ya que sin ninguna pena interpretaba canciones y le cambiaba la letra a estas sin saber que más adelante serviría de plataforma para lograr grandes composiciones que han marcado el rumbo de la música regional mexicana en los últimos tiempos..',
        videourl:
          'https://youtube.com/playlist?list=PLLlCI9n44muADESckd8b6oOL1cmfGLz35',
        coverImg: 'edenm.jpg',
        category: 'Regional',
        location: 'Mexico',
        locationImg: 'mexiflag.jpg',
        openSpots: null,
        likes: 1000,
        websiteurl: '',
        youtube: 'https://www.youtube.com/channel/UCJm7UPWbVhuNe3achnRi8iA',
        facebook: 'https://www.facebook.com/EdenMunozOficial/',
        instagram: 'https://www.facebook.com/EdenMunozOficial/',
        twitter: 'https://twitter.com/edenmunozof',
        spotify: 'https://open.spotify.com/artist/66ihevNkSYNzRAl44dx6jJ',
        deezer: 'https://www.deezer.com/mx/artist/4414006',
        apple: 'https://music.apple.com/mx/artist/eden-mu%C3%B1oz/383984059',
        soundcloud: 'https://soundcloud.com/edenmunoz-music',
        tidal: 'https://listen.tidal.com/artist/12062930',
        wiki: 'https://es.wikipedia.org/wiki/Ed%C3%A9n_Mu%C3%B1oz',
        ticketmaster:
          'https://www.ticketmaster.com/eden-munoz-tickets/artist/Z7r9jZa8yb',
        tiktok: 'https://www.tiktok.com/@edenmunoz1?lang=es',
        napster: 'https://web.napster.com/artist/eden-munoz-4',
      },
      {
        "title": "Empire of The Sun",
        "description": "Empire of the Sun es un dúo australiano de música electrónica formado en 2007. El dúo es una colaboración entre Luke Steele, anteriormente de la banda de rock alternativo The Sleepy Jackson, y Nick Littlemore, de la banda de música electrónica PNAU.El álbum debut de Empire of the Sun en 2008, Walking on a Dream, le trajo al dúo éxito internacional y ha sido certificado doble platino en Australia y oro en el Reino Unido. El álbum proporcionó varios sencillos que llegaron a las listas internacionales, incluyendo el tema principal, que alcanzó el número diez en la ARIA Singles Chart australiana, y We Are the People, que alcanzó el número catorce en la UK Singles Chart. El segundo álbum de la banda, Ice on the Dune, fue lanzado en junio de 2013, mientras que su tercer álbum, Two Vines, fue lanzado en octubre de 2016. Un cuarto álbum, Ask That God, fue lanzado en julio de 2024.",
        "videourl": "https://youtube.com/playlist?list=PL7-vLfOD02lTbzX5Q9j3V6MsjQ8b50W4c&si=HR3DBbe5Z6HlO9hL",
        "price": 80,
        "coverImg": "empireofthesun.jpg",
        "category": "Electro Dub",
        "location": "Austarlia",
        "locationImg": "australia.jpg",
        "openSpots": null,
        "likes": 1000,
        "websiteurl": "https://empireofthesun.co/",
        "youtube": "https://www.youtube.com/channel/UCyacMWc-JhzX6iq2PCF-4jw",
        "facebook": "https://www.facebook.com/empireofthesun?mibextid=uzlsIk",
        "instagram": "https://www.instagram.com/empireofthesunsound?igsh=a3N1NXJpbzkzdjQ3",
        "twitter": "https://x.com/empireofthesun?s=21&t=QfkoZv7VttIZngjRBBWSJQ",
        "spotify": "https://open.spotify.com/artist/67hb7towEyKvt5Z8Bx306c",
        "deezer": "https://www.deezer.com/mx/artist/185473",
        "apple": "https://music.apple.com/us/artist/empire-of-the-sun/284433289",
        "soundcloud": "https://soundcloud.com/empireofthesunsound",
        "tidal": "https://listen.tidal.com/artist/3542951",
        "wiki": "https://en.wikipedia.org/wiki/Empire_of_the_Sun_(band)",
        "ticketmaster": "https://www.ticketmaster.com/empire-of-the-sun-tickets/artist/1439524",
        "tiktok": "https://www.tiktok.com/@empireofthesun?_t=8kxI4KrA2JC&_r=1",
        "napster": "https://music.amazon.com/artists/B001Q1RXGQ/empire-of-the-sun"
      },
      {
        title: 'Camilo',
        description:
          'Camilo Andrés Echeverry Correa, conocido simplemente como Camilo, es un cantautor y músico colombiano. Debutó en 2008, después de ganar el concurso de talentos Factor X de su país en 2007.',
        videourl:
          'http://youtube.com/playlist?list=PLl9yuNCZfI6pQFNQ90lEAdv8VoCOUvE8i',
        price: '$50',
        coverImg: 'Camilo.jpg',
        category: 'Balada',
        location: 'Camilo',
        locationImg: 'coloflag.jpg',
        openSpots: null,
        likes: null,
        websiteurl: '',
        youtube: 'https://www.youtube.com/channel/UCw0aKHSGFGyrnMeVnHeYMyw',
        facebook: 'https://www.facebook.com/camilomusica',
        instagram: 'https://www.instagram.com/camilo/',
        twitter: 'https://twitter.com/CamiloMusica',
        spotify: 'https://open.spotify.com/artist/28gNT5KBp7IjEOQoevXf9N',
        deezer: 'https://www.deezer.com/mx/artist/58568762',
        apple: 'https://music.apple.com/mx/artist/camilo/1457386767',
        soundcloud: 'https://soundcloud.com/camiloofficial',
        tidal: 'https://listen.tidal.com/artist/3824470',
        wiki: 'https://es.wikipedia.org/wiki/Camilo',
        ticketmaster: 'https://www.ticketmaster.com./camilo-tickets/artist/2773173',
        tiktok: 'https://www.tiktok.com/@camilo?lang=es',
        napster: 'https://app.napster.com/artist/camilo',
      },
      {
        title: 'Cafe Tacvba',
        description:
          'Café Tacvba es una banda de rock alternativo procedente de Ciudad Satélite, Naucalpan, Estado de México. El grupo se conformó en 1989. Además del éxito comercial y de crítica que han logrado, el grupo es reconocido por su proyecto cultural vanguardista el cual mezcla el rock y sus temas habituales con letras, historias y sonidos extraídos de la cultura popular mexicana, esto último gracias al uso en diversas canciones de instrumentos como tololoche y jarana. Entre los principales premios obtenidos a lo largo de su carrera destacan el Grammy y el Grammy Latino..',
        videourl:
          'https://youtube.com/playlist?list=PLm45w0e6-VdHYy03NU_BVv1sPO6zHMGbM',
        price: '$50',
        coverImg: 'CafeTacuba.jpg',
        category: 'Rock en Español',
        location: 'Mexico',
        locationImg: 'mexiflag.jpg',
        openSpots: null,
        likes: 1000,
        websiteurl: 'https://cafetacuba.com.mx/',
        youtube: 'https://www.youtube.com/c/Caf%C3%A9Tacvba',
        facebook: 'https://www.facebook.com/cafetacvbaoficial',
        instagram: 'https://www.instagram.com/cafetacvba/',
        twitter: 'https://twitter.com/cafetacuba_ct',
        spotify: 'https://open.spotify.com/artist/09xj0S68Y1OU1vHMCZAIvz',
        deezer: 'https://www.deezer.com/mx/artist/74311',
        apple: 'https://music.apple.com/mx/artist/caf%C3%A9-tacvba/267781297',
        soundcloud: 'https://soundcloud.com/cafe-tacvba',
        tidal: 'https://listen.tidal.com/artist/12575',
        wiki: 'https://es.wikipedia.org/wiki/Caf%C3%A9_Tacvba',
        ticketmaster: 'https://www.ticketmaster.com.mx/search?q=Caf%C3%A9+Tacvba',
        tiktok: 'https://www.tiktok.com/@tiktacvba?lang=es',
        napster: 'https://music.amazon.com/artists/B000VZWALG/caf%C3%A9-tacvba',
      },
];
export default CardData17